<template>
    <div>
        <Loader v-if="isLoading" />
        <div v-else>
            <b-form @submit.prevent="onSubmit">
                <b-form-group id="input-group-name" label="Namn" label-for="input-name">
                    <b-form-input id="input-name"
                                  v-model="form.name"
                                  type="text"
                                  placeholder="Ange namn"
                                  :disabled="isSubmitting"
                                  autocomplete="off"
                                  aria-describedby="input-name-help-block"
                                  required />
                    <b-form-text id="input-name-help-block">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('Name')" :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-form-text>
                </b-form-group>

                <b-form-group id="input-group-address"
                              label="Adress"
                              label-for="input-address">
                    <b-form-input id="input-address"
                                  v-model="form.address"
                                  type="text"
                                  placeholder="Ange adress"
                                  :disabled="isSubmitting"
                                  autocomplete="off"
                                  aria-describedby="input-address-help-block"
                                  required />
                    <b-form-text id="input-address-help-block">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('Address')" :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-form-text>
                </b-form-group>

                <Base64Field :maxWidth="1024"
                             :maxHeight="768"
                             v-model="form.imageAsBase64"
                             :validation-messages="validationByKey('ImageAsBase64')"
                             :disabled="isSubmitting" />

                <b-form-group id="input-group-public"
                              label="Publik"
                              label-for="input-public">
                    <b-form-checkbox id="input-public"
                                     v-model="form.public"
                                     :disabled="isSubmitting"
                                     aria-describedby="input-public-help-block">
                        <ul class="text-danger mb-0">
                            <li v-for="message in validationByKey('Public')" :key="message">
                                {{ message }}
                            </li>
                        </ul>
                    </b-form-checkbox>
                </b-form-group>

                <b-alert :show="validationByKey('Other').length > 0" variant="danger">
                    <ul class="text-danger mb-0">
                        <li v-for="message in validationByKey('Other')" :key="message">
                            {{ message }}
                        </li>
                    </ul>
                </b-alert>

                <b-alert :show="successMessage.length > 0"
                         variant="success"
                         class="mb-0 mt-4">
                    <h6 class="mb-0 text-center">
                        <font-awesome-icon icon="info-circle" /> {{ successMessage }}
                    </h6>
                </b-alert>


                <div class="mt-4">
                    <b-button @click="
                            confirm(
                              'onDelete',
                              `Är du säker på att du vill ta bort fastighet '${form.name}'?`
                            )
                          "
                              variant="danger"
                              :disabled="isSubmitting">
                        <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Ta bort fastigheten
                    </b-button>

                    <b-button v-b-tooltip.hover
                              class="float-right"
                              variant="primary"
                              :disabled="isSubmitting"
                              type="submit"
                              :title="isSubmitting ? 'Sparar...' : ''">
                        <font-awesome-icon v-if="isSubmitting" icon="spinner" spin /> Spara
                    </b-button>
                </div>
            </b-form>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { get, post, remove } from '@/helpers/api';
    import Loader from '@/components/Loader';
    import Base64Field from '@/components/Base64Field';

    export default {
        name: 'SaveBuilding',
        components: {
            Loader,
            Base64Field
        },
        props: {
            value: Number
        },
        data() {
            return {
                isLoading: false,
                form: {
                    id: this.value,
                    customerId: 0,
                    name: '',
                    address: '',
                    imageAsBase64: null,
                    public: false
                },
                isSubmitting: false,
                successMessage: '',
                validationErrors: null
            };
        },
        computed: {
            isEditMode() {
                return this.form.id > 0;
            },
            ...mapState({
                selectedCustomerId: (state) => state.user.selectedCustomer.id
            })
        },
        async mounted() {
            this.getData();
        },
        methods: {
            confirm(action, message) {
                this.$bvModal
                    .msgBoxConfirm(message, {
                        title: 'Är du säker?',
                        size: 'sm',
                        //buttonSize: 'sm',
                        cancelVariant: 'primary',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'danger',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        if (confirmed) this[action]();
                    });
            },
            reset() {
                // Resets.
                this.successMessage = '';
                this.validationErrors = null;
                this.isSubmitting = true;
            },
            async getData() {
                if (this.isEditMode) {
                    this.isLoading = true;
                    await get('Building', `Get/${this.form.id}`)
                        .then((x) => {
                            this.form = x.data;
                            this.isLoading = false;
                        })
                        .catch((x) => {
                            alert(x.data);
                            this.isLoading = false;
                        });
                }
                else {
                    this.form.customerId = this.selectedCustomerId;
                }
            },
            async onDelete() {
                this.reset();

                // Post.
                await remove('Building', `Delete/${this.form.id}`)
                    .then((x) => {
                        this.$router.push({ name: 'manage-building-list' });
                    }).catch((x) => {
                        this.validationErrors = { Other: ['Det gick inte ta bort fastighet'] };
                        this.isSubmitting = false;
                    });
            },
            async onSubmit() {
                this.reset();

                // Post.
                await post('Building', `Save/${this.form.customerId}`, this.form)
                    .then((x) => {
                        if (this.form.id == 0) {
                            this.form.id = parseInt(x.data.entityId);
                            this.$emit('input', this.form.id);                       
                        }                           
                        this.successMessage = `Sparade ${this.form.name}`;
                        this.isSubmitting = false;
                    })
                    .catch((x) => {
                        this.validationErrors = x.response.data.errors;
                        this.isSubmitting = false;
                    });

                // On created.
                if (!this.validationErrors && this.form.id) this.onCreated();
            },
            onCreated() {
                this.hasSavedSuccessfullly = true;
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            }
        }
    };
</script>

<style scoped>
    .form-group {
        margin-bottom: 0.5rem;
    }
</style>

<style>
    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
        background-color: #007bff;
    }
</style>

<template>
    <div>
        <b-row>
            <b-col md="7" sm="12" class="my-1">
                <b-form-group label-for="filter-input"
                              label-align-sm="right"
                              class="mb-0">
                    <b-input-group>
                        <b-form-input id="filter-input"
                                      v-model="filter"
                                      type="search"
                                      autocomplete="off"
                                      placeholder="Filtrera...." />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">
                                Rensa
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
            <b-col sm="12" md="5" class="my-1">
                <b-form-group label="Per sida"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-align-sm="right"
                              class="mb-0">
                    <PaginationPageSizeSelect id="per-page-select"
                                              v-model="perPage"
                                              view="listBuildingPositionLacksHistory" />
                </b-form-group>
            </b-col>
            <b-col cols="12"
                   class="my-1 text-center">
                <b-row>
                    <b-col cols="8">
                        <b-pagination v-model="currentPage"
                                      v-show="totalRows > perPage"
                                      :total-rows="totalRows"
                                      :per-page="perPage"
                                      align="center"
                                      class="my-0" />
                    </b-col>
                    <b-col cols="4">
                        <b-form-group label="År"
                                      label-for="list-building-position-lacks-history--years"
                                      label-cols-sm="3"
                                      label-align-sm="right">
                            <b-form-select id="list-building-position-lacks-history--years"
                                           v-model="selectedYear"
                                           :options="historyYears"
                                           @change="onSelectedYearChange"/>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <!--TABLE-->
        <b-table :items="filteredPositions"
                 :fields="fields"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :sort-direction="sortDirection"
                 :current-page="currentPage"
                 :per-page="perPage"
                 :filter="filter"
                 :filter-included-fields="['concatenatedNumbers', 'articleName', 'lackDescription', 'fixedStatusDescription']"
                 @filtered="onFiltered"
                 @context-changed="onContextChanged"
                 stacked="sm"
                 show-empty
                 fixed
                 small
                 :busy.sync="isLoading">
            <template #cell(status)="row">
                <div class="overflow-hidden">
                    <span class="d-none d-xs-inline-block">Status: </span>
                    <ControlPositionStatusBall :status-id="row.item.statusId" />
                </div>
            </template>
            <template #cell(concatenatedNumbers)="row">
                <div class="overflow-hidden">
                    {{row.item.controlSerialNumber}}-{{ row.item.concatenatedNumbers }}
                </div>
            </template>
            <template #cell(articleName)="row">
                <div class="overflow-hidden">
                    <img v-if="row.item.fileSymbolId"
                         style="height: 1.5rem"
                         :src="getUrl(row.item.articleId)" />
                    {{ row.item.articleName }}
                </div>
            </template>
            <template #cell(lackDescription)="row">
                <div class="overflow-hidden">
                    {{ row.item.lackDescription }}
                </div>
            </template>
            <template #cell(fixedStatusDescription)="row">
                <div class="overflow-hidden">
                    {{ row.item.fixedStatusDescription }}
                </div>
            </template>
            <template #cell(controlCreatedDate)="row">
                <div class="overflow-hidden">
                    {{ row.item.controlCreatedDate | datetime('yyyy-MM-dd') }}
                </div>
            </template>
            <template #cell(controlDoneDate)="row">
                <div class="overflow-hidden">
                    {{ row.item.controlDoneDate | datetime('yyyy-MM-dd') }}
                </div>
            </template>
            <template #cell(actions)="row">
                <b-button class="text-center"
                          size="sm"
                          style="height: 26px; font-size: 12px;width:80%;"
                          @click="onOpenSidebar(row.item)">
                    Öppna
                </b-button>
            </template>
            <template #empty="">
                <h4 class="text-center">-</h4>
            </template>
            <template #emptyfiltered="">
                <div class="text-center">
                    <h4>Inga resultat</h4>
                    <h6>
                        <a href="#" @click="filter = ''; selectedYear = null;">
                            Klicka här för att rensa aktiv filtrering
                        </a>
                    </h6>
                </div>
            </template>
        </b-table>

        <!--Edit-->
        <b-sidebar v-if="sidebar.selected"
                   backdrop-variant="dark"
                   backdrop
                   right
                   lazy
                   shadow
                   :visible="sidebar.visible"
                   @hidden="onSidebarClosed">
            <template v-slot:title>
                <slot name="header">
                    <h4 class="text-center">
                        Brist  {{ sidebar.selected.concatenatedNumbers }}
                    </h4>
                </slot>
            </template>
            <SaveBuildingPositionLacks v-on:positionUpdated="onSidebarClosed"
                                       :position="sidebar.selected"
                                       :read-only="true" />
        </b-sidebar>
    </div>
</template>

<script>
    import { get } from '@/helpers/api';
    import {
        getDownloadUrlByArticleId
    } from '@/helpers/file.helper';
    import PaginationPageSizeSelect from '@/components/PaginationPageSizeSelect';
    import GenericSidebar from '@/components/GenericSidebar';
    import SaveBuildingPositionLacks from '@/components/building/SaveBuildingPositionLacks';
    import ControlPositionStatusBall from '@/components/control/ControlPositionStatusBall';
    import SendLackMailModal from '@/components/control/SendLackMailModal';
    import {
        getByKey as getFiltersForView,
        save as setFiltersForView
    } from '@/services/view-filter.service';

    export default {
        name: 'ListBuildingPositionLacks',
        components: {
            PaginationPageSizeSelect,
            GenericSidebar,
            SaveBuildingPositionLacks,
            ControlPositionStatusBall,
            SendLackMailModal
        },
        props: {
            buildingId: {
                type: Number,
                required: true
            },
            numberOfHistoryItems: {
                type: Number,
                default: 0
            }
        },
        computed: {
            filteredPositions() {
                if (this.selectedYear === null) {
                    this.setTableFilterProps(this.positions.length);
                    return this.positions;
                }

                const parsedYear = parseInt(this.selectedYear);

                const filtered = this.positions.filter(x =>
                    (parseInt(x.controlCreatedDateDisplayText) >= parsedYear && parseInt(x.controlCreatedDateDisplayText) <= parsedYear) ||
                    (parseInt(x.controlDoneDateDisplayText) >= parsedYear && parseInt(x.controlDoneDateDisplayText) <= parsedYear)
                );
                this.setTableFilterProps(filtered.length);
                return filtered;
            },
            historyYears() {
                return [{ value: null, text: 'Alla' }, ...new Set(this.positions.map(item => new Date(item.controlCreatedDate).getFullYear()))];
            }
        },
        data() {
            return {
                sidebar: {
                    visible: false,
                    selected: null
                },
                fields: [
                    {
                        key: 'status',
                        label: '',
                        thStyle: { 'width': '50px' }
                    },
                    {
                        key: 'concatenatedNumbers',
                        label: '#',
                        sortable: true,
                        sortDirection: 'desc',
                        thStyle: { 'width': '100px' }
                    },
                    {
                        key: 'articleName',
                        label: 'Artikel',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'lackDescription',
                        label: 'Brist',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'fixedStatusDescription',
                        label: 'Åtgärdstatus',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'controlCreatedDate',
                        label: 'Påbörjad',
                        sortable: true,
                        sortDirection: 'desc',
                        tdClass: 'text-right',
                        thClass: 'text-right',
                        thStyle: { 'width': '120px' }
                    },
                    {
                        key: 'controlDoneDate',
                        label: 'Avslutad',
                        sortable: true,
                        sortDirection: 'desc',
                        tdClass: 'text-right',
                        thClass: 'text-right',
                        thStyle: { 'width': '120px' }
                    },
                    {
                        key: 'actions',
                        label: '',
                        tdClass: ['table-list--actions--wrapper', 'text-right'],
                        thClass: 'text-right',
                        thStyle: { 'width': '110px' }
                    }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 5,
                sortBy: 'controlDoneDate',
                sortDesc: true,
                sortDirection: 'desc',
                filter: null,
                isLoading: true,
                positions: [],

                selectedYear: null
            };
        },
        async mounted() {
            await this.getData();
            this.setFilters(getFiltersForView('listBuildingPositionLacksHistory'));
            // Open sidebar if has entityId.
            if (this.$route.params.entityId)
                this.onOpenSidebar(this.positions.find(x => x.id === parseInt(this.$route.params.entityId)));
        },
        methods: {
            onSelectedYearChange() {
                setFiltersForView(
                    'listBuildingPositionLacksHistory',
                    {
                        sortBy: this.sortBy,
                        sortDesc: this.sortDesc,
                        sortDirection: this.sortDirection,

                        filter: this.filter,

                        currentPage: this.currentPage,
                        perPage: this.perPage,
                        selectedYear: this.selectedYear
                    }
                );
            },
            setFilters(viewFilters) {
                this.sortBy = viewFilters.sortBy;
                this.sortDesc = viewFilters.sortDesc;
                this.sortDirection = viewFilters.sortDirection;

                this.filter = viewFilters.filter;

                this.currentPage = viewFilters.currentPage;
                if (viewFilters.perPage) this.perPage = viewFilters.perPage;

                this.selectedYear = viewFilters.selectedYear;
            },
            onContextChanged(ctx) {
                if (!this.isLoading && ctx)
                    setFiltersForView(
                        'listBuildingPositionLacksHistory',
                        { ...ctx, selectedYear: this.selectedYear }
                    );

                if (ctx.perPage > this.totalRows)
                    this.currentPage = 1;
            },
            onFiltered(filteredItems) {
                this.setTableFilterProps(filteredItems.length);
            },
            setTableFilterProps(numberOfItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = numberOfItems;
                this.$emit('update:numberOfHistoryItems', numberOfItems);
            },
            async getData() {
                await get('Control', `GetLacksHistoryByBuildingId/${this.buildingId}`)
                    .then((x) => {
                        this.positions = x.data;
                        this.totalRows = this.positions.length;
                        this.$emit('update:numberOfHistoryItems', this.totalRows);
                        this.isLoading = false;
                    })
                    .catch((x) => {
                        this.isLoading = false;
                    });
            },
            getUrl(id) {
                return getDownloadUrlByArticleId(id);
            },
            onOpenSidebar(item) {
                this.sidebar.visible = true;
                this.sidebar.selected = item;
            },
            onSidebarClosed(savedItem) {
                this.sidebar = {
                    visible: false,
                    selected: null
                };
            }
        }
    };
</script>

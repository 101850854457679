<template>
    <div>
        <b-row>
            <b-col sm="8" xs="12">
                <h2>{{buildingName}} - Scheman</h2>
            </b-col>
            <b-col sm="4" xs="12" class="text-right" style="margin: auto">
                <b-button variant="primary" @click="add"> Lägg till </b-button>
            </b-col>
            <b-col md="7" sm="12" class="my-1">
                <b-form-group label-for="filter-input"
                              label-align-sm="right"
                              class="mb-0">
                    <b-input-group>
                        <b-form-input id="filter-input"
                                      v-model="filter"
                                      type="search"
                                      placeholder="Filtrera...." />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">
                                Rensa
                            </b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>

            <b-col sm="12" md="5" class="my-1">
                <b-form-group label="Per sida"
                              label-for="per-page-select"
                              label-cols-sm="6"
                              label-align-sm="right"
                              class="mb-0">
                    <PaginationPageSizeSelect id="per-page-select" 
                                              v-model="perPage"
                                              view="listBuildingSchedules" />
                </b-form-group>
            </b-col>

            <b-col v-show="totalRows > perPage" cols="12" class="my-1 text-center">
                <b-pagination v-model="currentPage"
                              :total-rows="totalRows"
                              :per-page="perPage"
                              align="center"
                              class="my-0" />
            </b-col>
        </b-row>

        <!--TABLE-->
        <b-table :items="schedules"
                 :fields="fields"
                 :current-page="currentPage"
                 :per-page="perPage"
                 :filter="filter"
                 :filter-included-fields="[
                    'name',
                    'userFullName',
                    'controlNextDisplayDate'
                  ]"
                 :sort-by.sync="sortBy"
                 :sort-desc.sync="sortDesc"
                 :sort-direction="sortDirection"
                 :busy="isLoading"
                 stacked="sm"
                 @context-changed="onContextChanged"
                 show-empty
                 small
                 @filtered="onFiltered">
            <template #cell(name)="row">
                <div class="overflow-hidden">
                    {{ row.item.name }}
                </div>
            </template>
            <template #cell(userFullName)="row">
                <div class="overflow-hidden">
                    {{ row.item.userFullName }}
                </div>
            </template>
            <template #cell(controlNextDisplayDate)="row">
                <div class="overflow-hidden">
                    {{ row.item.controlNextDisplayDate }}
                </div>
            </template>
            <template #cell(actions)="row">
                <!-- Open sidebar. -->
                <font-awesome-icon class="d-none d-sm-block text-secondary"
                                   icon="pen-square"
                                   size="lg"
                                   role="button"
                                   @click="edit(row.item)" />

                <b-button class="text-center d-block d-sm-none"
                          block
                          @click="edit(row.item)">
                    Mer
                </b-button>
                <!-- ./ Open sidebar. -->
            </template>
            <template #empty="">
                <h4 class="text-center">Inga resultat</h4>
            </template>
            <template #emptyfiltered="">
                <div class="text-center">
                    <h4>Inga resultat</h4>
                    <h6>
                        <a href="#" @click="filter = ''">
                            Klicka här för att rensa aktiv filtrering
                        </a>
                    </h6>
                </div>
            </template>
            <template #table-busy>
                <div class="text-center mt-4">
                    <font-awesome-icon class="align-middle"
                                       icon="spinner"
                                       size="2x"
                                       spin />
                </div>
            </template>
        </b-table>

        <!--SIDEBAR-->
        <GenericSidebar :visible.sync="sidebar.visible"
                        :is-edit-mode.sync="sidebar.isEditMode"
                        :fields="[
                            { key: 'name', label: 'Namn' },
                            { key: 'userFullName', label: 'Ansvarig' },
                            { key: 'controlNextDisplayDate', label: 'Nästa kontroll' },
                            { key: 'recurrentDisplayText', label: 'Återkommer' },
                            { key: 'activeDisplayText', label: 'Aktiv' },
                            { key: 'reminderDisplayText', label: 'Påminnelse' },
                            { key: 'isServiceDisplayText', label: 'Service' },
                            { key: 'reminderMailList', label: 'Mailpåminnelser' }
                          ]"
                        :item="sidebar.selected"
                        width-when-editing="650px"
                        @update:updateData="getData">
            <template v-slot:header>
                {{ sidebar.selected.id > 0 ? 'Ändra' : 'Skapa' }} schema
            </template>
            <template v-slot:edit>
                <b-button v-if="!sidebar.isEditMode"
                          v-b-tooltip.hover
                          variant="info"
                          block
                          class="float-right mb-2"
                          :disabled="isCreatingControl || !sidebar.selected.id"
                          :title="isCreatingControl ? 'Skapar...' : ''"
                          @click="createControl">
                    <font-awesome-icon v-if="isCreatingControl" icon="spinner" spin /> Skapa ny egenkontroll
                </b-button>

                <b-button v-if="!sidebar.isEditMode"
                          variant="secondary"
                          class="mb-2"
                          block
                          @click="sidebar.isEditMode = true">
                    Ändra
                </b-button>
                <SaveBuildingSchedule v-if="sidebar.isEditMode"
                                      :id="sidebar.selected.id"
                                      :building-id="buildingId" />

                <b-button v-if="!sidebar.isEditMode"
                          variant="danger"
                          class="mb-2"
                          block
                          @click="confirm('removeSchedule', sidebar.selected.id, `Du kommer ta bort schemat '${sidebar.selected.name}'`, )">
                    Ta bort
                </b-button>
            </template>
        </GenericSidebar>
    </div>
</template>
<script>
    import {
        getByKey as getFiltersForView,
        save as setFiltersForView
    } from '@/services/view-filter.service';
    import { get, remove } from '@/helpers/api';
    import PaginationPageSizeSelect from '@/components/PaginationPageSizeSelect';
    import SaveBuildingSchedule from '@/components/building/SaveBuildingSchedule';
    import GenericSidebar from '@/components/GenericSidebar';

    export default {
        name: 'ListBuildingSchedules',
        components: {
            PaginationPageSizeSelect,
            SaveBuildingSchedule,
            GenericSidebar
        },
        props: {
            buildingId: {
                type: Number,
                required: true
            },
            buildingName: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                isCreatingControl: false,
                sidebar: {
                    selected: {
                        id: 0
                    },
                    visible: false,
                    isEditMode: false
                },
                fields: [
                    { key: 'name', label: 'Namn', sortable: true, sortDirection: 'desc' },
                    {
                        key: 'userFullName',
                        label: 'Kontrollant',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'controlNextDisplayDate',
                        label: 'Nästa kontroll',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    { key: 'actions', label: '', tdClass: 'table-list--actions--wrapper' }
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 5,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                isLoading: true,
                schedules: null
            };
        },
        async mounted() {
            await this.getData();
            this.setFilters(getFiltersForView('listBuildingSchedules'));
        },
        methods: {
            setFilters(viewFilters) {
                this.sortBy = viewFilters.sortBy;
                this.sortDesc = viewFilters.sortDesc;
                this.sortDirection = viewFilters.sortDirection;

                this.filter = viewFilters.filter;

                this.currentPage = viewFilters.currentPage;
                if (viewFilters.perPage) this.perPage = viewFilters.perPage;
            },
            onContextChanged(ctx) {
                if (!this.isBusy && ctx)
                    setFiltersForView('listBuildingSchedules', ctx);

                if (ctx.perPage > this.totalRows)
                    this.currentPage = 1;
            },
            add() {
                this.sidebar = {
                    selected: {
                        id: 0
                    },
                    visible: true,
                    isEditMode: true
                };
            },
            edit(item) {
                this.sidebar.selected = item;
                this.sidebar.visible = true;
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
            },
            async getData() {
                await get('BuildingSchedule', `GetAllByBuildingId/${this.buildingId}`)
                    .then((x) => {
                        this.schedules = x.data;
                        this.totalRows = this.schedules.length;
                        this.isLoading = false;
                    })
                    .catch((x) => {
                        this.isLoading = false;
                    });
            },
            async removeSchedule(id) {
                await remove('BuildingSchedule', `Delete/${id}`)
                    .then((x) => {
                        // Reset and close sidebar.
                        this.sidebar.visible = false;
                        this.sidebar.selected = {
                            id: 0
                        };
                        // Remove item from list.
                        this.schedules.splice(
                            this.schedules.findIndex(x => x.id === id), 1
                        );
                        // Update total rows.
                        this.totalRows = this.schedules.length;
                    })
                    .catch((x) => {
                        alert('Det gick inte ta bort schemat. Försök igen.');
                    });
            },
            confirm(action, data, message) {
                this.$bvModal
                    .msgBoxConfirm(message, {
                        title: 'Är du säker?',
                        size: 'sm',
                        cancelVariant: 'primary',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'danger',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        if (confirmed) this[action](data);
                    })
                    .catch((err) => {
                        // An error occurred
                    });
            },
            async confirmRouteToNewelyCreatedControl(controlId) {
                return await this.$bvModal
                    .msgBoxConfirm('Vill du gå direkt till kontrollen?', {
                        title: 'Egenkontroll har skapats',
                        size: 'sm',
                        //buttonSize: 'sm',
                        cancelVariant: 'warning',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'primary',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        if (confirmed)
                            this.$router.push({ name: 'do-control', params: { id: controlId } });
                    });
            },
            async createControl() {
                this.isCreatingControl = true;
                await get('Control', `Create/${this.sidebar.selected.id}`)
                    .then((x) => {
                        this.isCreatingControl = false;
                        this.confirmRouteToNewelyCreatedControl(x.data);
                    })
                    .catch(() => {
                        this.isCreatingControl = false;
                        alert('Något gick snett vid skapa egenkontroll.');
                    });
            }
        }
    };
</script>

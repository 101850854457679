<template>
  <b-form-group>
    <b-form-checkbox-group
      v-model="selectedSchedules"
      :disabled="isSubmitting"
      :options="allSchedules"
      class="custom-checkbox-group"
      plain
      size="lg"
      stacked
      @change="onChange"
    />

    <!--Alerts-->
    <div class="mt-3">
      <b-alert variant="success" fade :show="success">
        <h6 class="mb-0 text-center">
          <font-awesome-icon icon="info-circle" /> Sparat!
        </h6>
      </b-alert>
      <b-alert variant="danger" fade :show="error">
        Något gick snett. Ladda om sidan!
      </b-alert>
    </div>
  </b-form-group>
</template>

<script>
import { get, post } from '@/helpers/api';
import { mapActions } from 'vuex';

export default {
  name: 'BuildingPartPositionInSchedules',
  props: {
    buildingPartPositionId: {
      type: Number,
      required: true
    },
    buildingId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      error: false,
      success: false,
      isSubmitting: false,
      allSchedules: null,
      selectedSchedules: null
    };
  },
  mounted() {
    this.getSchedules();
  },
  methods: {
    ...mapActions('buildingPositions', ['setIsPositionNotInAnySchedule']),
    async onChange(e) {
      this.success = false;
      this.error = false;
      this.isSubmitting = true;
      
      await this.setPositionsToSchedule(e);
    },
    getSchedules() {
      get(
        'BuildingSchedule',
        `GetAllSimpleByBuildingId/${this.buildingId}`
      ).then((x) => {
        this.allSchedules = x.data.map((x) => {
          return { text: x.name, value: x.id };
        });

        get(
          'BuildingSchedule',
          `GetAllSimpleByPositionId/${this.buildingPartPositionId}`
        ).then((x) => {
          this.selectedSchedules = x.data.map((x) => x.id);
        });
      });
    },
    async setPositionsToSchedule(selectedScheduleIds) {
      await post(
        'BuildingSchedule',
        `ReplaceAllSchedulePositions/${
          this.buildingPartPositionId
        }${this.getQuery(selectedScheduleIds)}`
      )
      .then(() => {
        this.setIsPositionNotInAnySchedule({ id: this.buildingPartPositionId, isPositionNotInAnySchedule: selectedScheduleIds.length === 0 });
        this.success = true;
        this.isSubmitting = false;
        setTimeout(() => (this.success = false), 1500);
      })
      .catch((x) => {
         this.error = true;
         this.isSubmitting = false;
      });
    },
    getQuery(scheduleIds) {
      let query = '?';
      for (var i = 0; i < scheduleIds.length; i++)
        query += `scheduleIds=${scheduleIds[i]}&`;
      return query;
    }
  }
};
</script>

<style>
.custom-checkbox-group input[type='checkbox'] {
  width: 1rem;
  height: 1.25rem;
}

.custom-checkbox-group label {
  font-size: 1.25rem;
  margin-left: 0.25rem;
  line-height: 1.3;
}

.custom-checkbox-group .form-check:hover,
.custom-checkbox-group .form-check:active,
.custom-checkbox-group .form-check:focus {
  background-color: #efefef;
}
</style>
